<template>
    <a
        href="#"
        class="d-block slide-move-btn d-flex align-center justify-center no-user-select"
        :aria-label="alt"
    >
        <img
            :src="imgUrl"
            loading="lazy"
            :alt="alt"
        />
    </a>
</template>

<script lang="ts" setup>
    import { useCdnResource } from '~/composables';
    
    const props = defineProps<{
        type: 'next' | 'prev';
        alt: string;
    }>();
    
    const { cdnBasePathMain } = useCdnResource();

    const imgUrl = ref(`${cdnBasePathMain}/static/common/images/svg/symbols/chevron-${props.type === 'prev' ? 'left' : 'right' }.svg`);
</script>

<style lang="scss">
.slide-move-btn {
    width: 56px;
    height: 98px;
    background: rgba(100, 100, 100, 0.5);
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.4;

    img {
        cursor: pointer;
        width: auto;
        height: 50px;
        mix-blend-mode: color-burn;
    }

    &:active {
        background: rgba(100, 100, 100, 1);
    }

    &:hover {
        opacity: 0.8;
    }
}
</style>
